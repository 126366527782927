export const NFTCONTRACT = "0xd36ac1045e34a4e2EC88A8e43022514764386636"
export const STAKINGCONTRACT = "0x4b5e29fF0f84776f936198Fcd8ca598e111B1aF1"
export const bscscanapi = "https://api.bscscan.com/"
export const moralisapi = "https://deep-index.moralis.io/api/v2/";
export const nftpng = "https://ipfs.io/ipfs/QmQdGkvzVBknvhQTb3WGwodqBDtdPwG47BKpuDq3KgKQ72/";


/*
    const unstakednfts = await contract.methods.walletOfOwner(account).call()
      .then(id => {
          return id;
          console.log(id);
        })
        const nftunstk = await Promise.all(unstakednfts.map(async i => {
          let unstkid = {
            tokenId: i,
          }
          return unstkid
        }))
        getNfts(apicall)
        getunStk(nftunstk)
        getStk(nftstk)
        console.log(apicall);
        setLoadingState('loaded')
      }


      const NFTCONTRACT = "0x06A546A6FeE87aBc45f32dFb630b9A4c69eA243a";
const STAKINGCONTRACT = "0x32b598a60EBe5f3b06d4b9D6571D2a8d9fe0d7E8";
const polygonscanapikey = "DBQX5JUSAVUZRK8CC4IN2UZF9N2HA63P4U";
const polygonscanapi = "https://api-testnet.polygonscan.com/api"
const moralisapi = "https://deep-index.moralis.io/api/v2/";
const moralisapikey = "pC41kxQ1eakXfyd0ME3DEgmGGkTxQnF0iLLygWMeeDni2mLrGoKmNw5hxUKWIWHC";
const nftpng = "https://ipfs.io/ipfs/QmYh538cj3pfW1uPeKB86ihMNuaAitoZv14T2ZFjxoPjBj/";

*/

/* 
    const unstakednfts = await contract.methods.walletOfOwner(account).call()
    .then(id => {
      return id;
    })
    const nftunstk = await Promise.all(unstakednfts.map(async i => {
      let stkid = {
        tokenId: 1,
      }
      return stkid;
    }))
*/