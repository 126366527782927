import './App.css';
import { Button } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import React from 'react';
import 'sf-font';
import Web3Modal from "web3modal";
import WalletConnectProvider from "@walletconnect/web3-provider";
import Web3 from 'web3';
import truncateEthAddress from 'truncate-eth-address';
import { Navbar,Nav,NavDropdown,Form,FormControl } from 'react-bootstrap'
import LOGO from './art.png'
import PRIZE2 from './Prize2.png'
import { Route, Link } from 'react-router-dom';
import App from './App'
import styled from "styled-components";

const ABI = [
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "_newDuration",
				"type": "uint256"
			}
		],
		"name": "changeDuration",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint8[]",
				"name": "tickets",
				"type": "uint8[]"
			}
		],
		"name": "enter",
		"outputs": [],
		"stateMutability": "payable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "_newMaxAmountPerPlayer",
				"type": "uint256"
			},
			{
				"internalType": "uint256",
				"name": "_newFee",
				"type": "uint256"
			}
		],
		"name": "maxParams",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [],
		"stateMutability": "nonpayable",
		"type": "constructor"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "have",
				"type": "address"
			},
			{
				"internalType": "address",
				"name": "want",
				"type": "address"
			}
		],
		"name": "OnlyCoordinatorCanFulfill",
		"type": "error"
	},
	{
		"anonymous": false,
		"inputs": [
			{
				"indexed": true,
				"internalType": "address",
				"name": "previousOwner",
				"type": "address"
			},
			{
				"indexed": true,
				"internalType": "address",
				"name": "newOwner",
				"type": "address"
			}
		],
		"name": "OwnershipTransferred",
		"type": "event"
	},
	{
		"anonymous": false,
		"inputs": [
			{
				"indexed": false,
				"internalType": "address",
				"name": "account",
				"type": "address"
			}
		],
		"name": "Paused",
		"type": "event"
	},
	{
		"inputs": [],
		"name": "payoutLottery",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "pickWinner",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "requestId",
				"type": "uint256"
			},
			{
				"internalType": "uint256[]",
				"name": "randomWords",
				"type": "uint256[]"
			}
		],
		"name": "rawFulfillRandomWords",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "renounceOwnership",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "start",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "newOwner",
				"type": "address"
			}
		],
		"name": "transferOwnership",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "_amount",
				"type": "uint256"
			},
			{
				"internalType": "address",
				"name": "_destination",
				"type": "address"
			}
		],
		"name": "transferTokens",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"anonymous": false,
		"inputs": [
			{
				"indexed": false,
				"internalType": "address",
				"name": "account",
				"type": "address"
			}
		],
		"name": "Unpaused",
		"type": "event"
	},
	{
		"inputs": [],
		"name": "duration",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "_id",
				"type": "uint256"
			}
		],
		"name": "endTime",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "fee",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "isActive",
		"outputs": [
			{
				"internalType": "bool",
				"name": "",
				"type": "bool"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "lotteryID",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "maxTicketsPerPlayer",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			},
			{
				"internalType": "address",
				"name": "",
				"type": "address"
			}
		],
		"name": "numTicketsBought",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "owner",
		"outputs": [
			{
				"internalType": "address",
				"name": "",
				"type": "address"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "_id",
				"type": "uint256"
			}
		],
		"name": "pastEntries",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "_id",
				"type": "uint256"
			}
		],
		"name": "pastWinner",
		"outputs": [
			{
				"internalType": "address",
				"name": "",
				"type": "address"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "paused",
		"outputs": [
			{
				"internalType": "bool",
				"name": "",
				"type": "bool"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"name": "players",
		"outputs": [
			{
				"internalType": "address",
				"name": "",
				"type": "address"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "previousWinner",
		"outputs": [
			{
				"internalType": "address",
				"name": "",
				"type": "address"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			},
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"name": "s_randomWords",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "s_requestId",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "tokenAddress",
		"outputs": [
			{
				"internalType": "address",
				"name": "",
				"type": "address"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "totalEntries",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "user",
				"type": "address"
			}
		],
		"name": "userEntries",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	}
];

const TOKENABI = [
  { inputs: [], stateMutability: "nonpayable", type: "constructor" },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: "address",
        name: "owner",
        type: "address",
      },
      {
        indexed: true,
        internalType: "address",
        name: "spender",
        type: "address",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "value",
        type: "uint256",
      },
    ],
    name: "Approval",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: "address",
        name: "previousOwner",
        type: "address",
      },
      {
        indexed: true,
        internalType: "address",
        name: "newOwner",
        type: "address",
      },
    ],
    name: "OwnershipTransferred",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      { indexed: true, internalType: "address", name: "from", type: "address" },
      { indexed: true, internalType: "address", name: "to", type: "address" },
      {
        indexed: false,
        internalType: "uint256",
        name: "value",
        type: "uint256",
      },
    ],
    name: "Transfer",
    type: "event",
  },
  {
    inputs: [{ internalType: "address", name: "controller", type: "address" }],
    name: "addController",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      { internalType: "address", name: "owner", type: "address" },
      { internalType: "address", name: "spender", type: "address" },
    ],
    name: "allowance",
    outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      { internalType: "address", name: "spender", type: "address" },
      { internalType: "uint256", name: "amount", type: "uint256" },
    ],
    name: "approve",
    outputs: [{ internalType: "bool", name: "", type: "bool" }],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [{ internalType: "address", name: "account", type: "address" }],
    name: "balanceOf",
    outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [{ internalType: "uint256", name: "amount", type: "uint256" }],
    name: "burn",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      { internalType: "address", name: "account", type: "address" },
      { internalType: "uint256", name: "amount", type: "uint256" },
    ],
    name: "burnFrom",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [],
    name: "decimals",
    outputs: [{ internalType: "uint8", name: "", type: "uint8" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      { internalType: "address", name: "spender", type: "address" },
      { internalType: "uint256", name: "subtractedValue", type: "uint256" },
    ],
    name: "decreaseAllowance",
    outputs: [{ internalType: "bool", name: "", type: "bool" }],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      { internalType: "address", name: "spender", type: "address" },
      { internalType: "uint256", name: "addedValue", type: "uint256" },
    ],
    name: "increaseAllowance",
    outputs: [{ internalType: "bool", name: "", type: "bool" }],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      { internalType: "address", name: "to", type: "address" },
      { internalType: "uint256", name: "amount", type: "uint256" },
    ],
    name: "mint",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [],
    name: "name",
    outputs: [{ internalType: "string", name: "", type: "string" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "owner",
    outputs: [{ internalType: "address", name: "", type: "address" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [{ internalType: "address", name: "controller", type: "address" }],
    name: "removeController",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [],
    name: "renounceOwnership",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [],
    name: "symbol",
    outputs: [{ internalType: "string", name: "", type: "string" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "totalSupply",
    outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      { internalType: "address", name: "to", type: "address" },
      { internalType: "uint256", name: "amount", type: "uint256" },
    ],
    name: "transfer",
    outputs: [{ internalType: "bool", name: "", type: "bool" }],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      { internalType: "address", name: "from", type: "address" },
      { internalType: "address", name: "to", type: "address" },
      { internalType: "uint256", name: "amount", type: "uint256" },
    ],
    name: "transferFrom",
    outputs: [{ internalType: "bool", name: "", type: "bool" }],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [{ internalType: "address", name: "newOwner", type: "address" }],
    name: "transferOwnership",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
];

var web3 = null;
var account = null;
var contract = null;
var tokencontract = null;

const TOKENADDRESS = "0x824293562F37Dc988f9816A5aD3fefE139A9B2cc";
const ADDRESS = "0x860A4eB3A2da9d054FD309F1bf853A95Dd88701E";
//"0x3faCB7e06489F17B8BbA2fe42C7Bf1F0565878dd";
// Old JokerLottery "0x97D932A3eA6Cf4Ee61b2E9E41f228aBbf8Fc4E8B";

const providerOptions = {
  walletconnect: {
    package: WalletConnectProvider,
    options: {
      rpc: {
        56: 'https://bsc-dataseed1.binance.org'
      },
      chainId: 56
    }
  }
}

const web3Modal = new Web3Modal({
  network: "mainnet", // optional
  cacheProvider: true, // optional
  providerOptions, // required
  theme: "dark",
});


  async function connectwallet() {
    var provider = await web3Modal.connect();
    web3 = new Web3(provider);
    var accounts = await web3.eth.getAccounts();
    account = accounts[0];
    var finalAccount = `${truncateEthAddress(account)}`
    document.getElementById('wallet-address').textContent = finalAccount;
    contract = new web3.eth.Contract(ABI, ADDRESS);
  tokencontract = new web3.eth.Contract(TOKENABI, TOKENADDRESS);
  var getbalance = await tokencontract.methods.balanceOf(account).call()
  var int = String(getbalance).split(",")[0];
  var balrwd = Web3.utils.fromWei(int);
  var totalbal = Number(balrwd).toFixed(2);
  document.getElementById("total-token").textContent = totalbal;
  var lotto = await contract.methods.lotteryID().call();
  document.getElementById("lottery-id").textContent = lotto;
  var ticketAmount = await contract.methods.numTicketsBought(lotto, account).call();
  document.getElementById("total-ticket").textContent = ticketAmount;
  var TotalTicketAmount = await contract.methods.totalEntries().call();
  document.getElementById("total-ticket-amount").textContent = TotalTicketAmount;
  /* var winner = await contract.methods.previousWinner().call()
  console.log(winner);
  document.getElementById("winner").textContent = winner; */
 var maxRx = await contract.methods.maxTicketsPerPlayer().call()
 console.log(maxRx);
document.getElementById("max-slot").textContent = maxRx;
  var btndis = document.getElementById('enter-btn');
  var getLotteryState = await contract.methods.isActive().call();
  console.log(getLotteryState);
  if(getLotteryState == false) {
    btndis.style.display = "none";
  document.getElementById("closed-lotto").style.display = "flex";
  }
  fee();
  balance()
}
async function enter() {
  var _ticketAmount = Number(document.querySelector("[name=amount]").value);
  contract.methods.enter([_ticketAmount]).send({ from: account });
}

/* async function balance () {
        const etherscan = await axios.get(endpoint + `?module=account&action=tokenbalance&contractaddress=${TOKENADDRESS}&address=${account}&tag=latest&apikey=${apikey}`)
        document.getElementById('balance').innerHTML = etherscan;
    } */

    async function balance() {
      var getbalance = await tokencontract.methods.balanceOf(account).call()
      var int = String(getbalance).split(",")[0];
      var balrwd = Web3.utils.fromWei(int);
      var totalbal = Number(balrwd).toFixed(2);
      document.getElementById("total-token").textContent = totalbal;
      var lotto = await contract.methods.lotteryID().call();
      document.getElementById("lottery-id").textContent = lotto;
      var ticketAmount = await contract.methods.numTicketsBought(lotto, account).call();
      document.getElementById("total-ticket").textContent = ticketAmount;
	  var TotalTicketAmount = await contract.methods.totalEntries().call();
      document.getElementById("total-ticket-amount").textContent = TotalTicketAmount;
      var btndis = document.getElementById('enter-btn');
      var getLotteryState = await contract.methods.isActive().call();
      console.log(getLotteryState);
      if(getLotteryState == false) {
        btndis.style.display = "none";
      document.getElementById("closed-lotto").style.display = "flex";
      }
      fee();
     var maxRx = await contract.methods.maxTicketsPerPlayer().call()
     console.log(maxRx);
    document.getElementById("max-slot").textContent = maxRx;
    var checkApprovalStats = await tokencontract.methods.allowance(account, ADDRESS).call();
    console.log(checkApprovalStats)
    var init = checkApprovalStats.toString();
    var balapp = Web3.utils.fromWei(init);
    var totalapp = Number(balapp);
    console.log(totalapp)
    if(totalapp <= 1000) {
      document.getElementById("approval").style.display = "flex"
    }
    
    }


async function approve() {
  var val = Web3.utils.toWei("1000000000000000000000000000000000000000000000000000", "ether");
  tokencontract.methods.approve(`${ADDRESS}`, val).send({ from: account });
}

async function fee() {
	var entrefee = await contract.methods.fee().call();
	var stringfee = entrefee.toString();
	var feeno = web3.utils.fromWei(stringfee, 'ether');
	document.getElementById("lottery-fee").textContent = feeno;
  }


  function refreshPage() {
    window.location.reload(false);
  }



function Lottery () {
  return(
    <div className='App'>
<div>
                  <div>
				  <Navbar bg="dark" variant="dark" expand="lg" sticky="top">
                        <Navbar.Toggle aria-controls="basic-navbar-nav" />
                        <Navbar.Collapse id="basic-navbar-nav" style={{justifyContent:"space-between"}}>
                            <Nav className="mr-auto">
                            <Nav.Link href="https://www.crazyjokersweepfestival.com"><b>Home</b></Nav.Link>
                            <Nav.Link href="https://rareboard.com/crazyjokersweepfestival">Marketplace</Nav.Link>
              <NavDropdown title="Staking" id="basic-nav-dropdown">
			  <Nav.Link> <Link style={{textDecoration:"none",color:"#000000"}} to="/cjsfstaking">CJSF</Link></Nav.Link>  
			  <Nav.Link> <Link style={{textDecoration:"none",color:"#000000"}} to="/AlienPetz">Alien Petz</Link></Nav.Link>  
        <Nav.Link> <Link style={{textDecoration:"none",color:"#000000"}} to="/CandyBotz">Candy Botz</Link></Nav.Link>  
              <NavDropdown.Divider />
              <NavDropdown.Item disabled href="/sideshow">SideShow</NavDropdown.Item>
              </NavDropdown>
              <NavDropdown title="Games" id="basic-nav-dropdown">
			  <Nav.Link> <Link style={{textDecoration:"none",color:"#000000"}} to="/lottery">Candy Raffle</Link></Nav.Link>  
			  <Nav.Link> <Link style={{textDecoration:"none",color:"#000000"}} to="/lottery2">Mystery NFT</Link></Nav.Link>  
			  <Nav.Link> <Link style={{textDecoration:"none",color:"#000000"}} to="/Poker">Poker Night</Link></Nav.Link>  
              </NavDropdown>
               <NavDropdown title="Mint" id="basic-nav-dropdown">
			  <Nav.Link> <Link style={{textDecoration:"none",color:"#000000"}} to="/CJSFMint">Crazy Joker Sweep Festival</Link></Nav.Link> 
			  <Nav.Link> <Link style={{textDecoration:"none",color:"#000000"}} to="/CandyBotzMint">Candy Botz</Link></Nav.Link> 
              </NavDropdown>
              <NavDropdown title="Socials" id="basic-nav-dropdown">
              <Nav.Link style={{textDecoration:"none",color:"#000000"}} href="https://twitter.com/crazyjokerparty">Twitter</Nav.Link>
              <Nav.Link style={{textDecoration:"none",color:"#000000"}} href="https://t.me/crazyjokersweepfestival">Telegram</Nav.Link> 
              </NavDropdown>
                           <Nav.Link href="https://pancakeswap.finance/swap?outputCurrency=0x824293562F37Dc988f9816A5aD3fefE139A9B2cc"><b>Buy $CANDY</b></Nav.Link>
                            </Nav>
                            <label type="button" className="btn btn-secondary" onClick={connectwallet} for="floatingInput" id="wallet-address" style={{color: "darkblue",fontWeight: "700",marginTop: "0px",marginRight: "8px",textShadow: "1px 1px black",}}>Connect Wallet</label>
                        </Navbar.Collapse>
                    </Navbar>
                    </div>
                    <div>
                </div>
          <div className='col' style={{color:"#5c4673"}}><br/>
            <a href='https://nftvs.space' ><img src={LOGO} alt="logo" width='70%' /></a>
          </div>
        </div>
      <div className='card mx-auto text-center' id='wrapper' style={{width:"80vw",marginTop:"10px",backgroundColor:"green"}}>
        <div className='card mx-auto text-center' id='item' style={{backgroundColor: "#2a2673",width:"70%",marginTop:"20px"}}>
          <h3 className='card-title' style={{textDecoration:"underline"}}>Mystery NFT Dashboard</h3>
		  <div style={{color:"white",fontSize:"24px"}}>Current Mystery Sale #: <span id='lottery-id'></span></div>
		  Each Mystery NFT ticket gets:
		  <a href='#' ><img src={PRIZE2} alt="Prize" width='30%' /></a>
		  1 Surprise Mystery NFTs from the Crazy Vault<br/><br/>
		  <h5 style={{ color: "yellow",}}>NFTs will be chosen by a live dice roll.<br/></h5>
		  <h7 style={{ color: "pink",}}>NFTs Possible: Nibblers, CrazyApez 2D, Ape Hybrid Society, Apes Mania, K(AI), PsyBears, MetaRaffes, Ordinary Octopus, Moonwalkers Club, Cryptobot 2D, or NFTVs<br/></h7>
		  <h4 style={{ color: "white",marginTop:"10px"}}><div className='card-title'style={{color:"orange"}}>You Have: <span id='total-ticket'></span>&nbsp;Mystery tickets.</div></h4>
		  <h5 style={{ color: "white",}}>Max tickets per player: <span id='max-slot'></span><br/></h5>
		  <h5 style={{ color: "white",}}><div className='card-title'style={{color:"cyan"}}>Total Mystery Tickets Sold: <span id='total-ticket-amount'></span>&nbsp;</div></h5>
		  <h5 style={{ color: "white",}}>Sale Ends: December 9th 4:00am UTC<br/></h5>
		  <h5 style={{ color: "grey",}}>All Mystery NFTs will be airdropped to you at the end of sale.</h5>
		</div>
    <h6 id='approval' className='card mx-auto text-center' style={{display:"none",color:"red",marginTop:"30px",fontSize:"18px",fontWeight:"bolder"}}>&nbsp;&nbsp;&nbsp;First time entering this sale?<br/> Scroll down and click 'Approve' to <br/>grant wallet access to the dApp.</h6>
        <div className='card mx-auto text-center' id='item2' style={{backgroundColor: "#3f97a1",width:"70%",marginTop:"24px",marginBottom:"24px"}}>
        <h3 className='card-title' style={{textDecoration:"underline",color:'white'}}>Ticket Gate</h3>
          <div className='card-title' style={{color:"white",fontWeight:"600"}}><input id="inpt" type="number" name="amount" defaultValue="1" style={{ textAlign: "center", width: "25%",borderRadius:"10px",backgroundColor:"gray" }} />
                <br></br>
                <label for="inpt" style={{ color: "white", fontWeight: "500" }}>
                  Enter Ticket Amount
                </label></div>
          <Button onClick={enter} id='enter-btn' className='btn btn-success mx-auto text-center' style={{width:'fit-content',marginBottom:"10px"}}>Buy Mystery Ticket(s)</Button>
          <Button id='closed-lotto' className='btn btn-danger mx-auto text-center'style={{display:"none",textAlign:"center"}}>Sale is Currently Closed</Button>
          <div className='card-title' style={{color:"white",fontWeight:"600"}}>Price: <span id='lottery-fee'></span> $CANDY Per Raffle Ticket</div>
		  <div className='card-title'style={{color:"yellow"}}>Balance: <span id='total-token'></span>&nbsp; $CANDY</div>
		  <Button onClick={balance} className='btn btn-primary mx-auto text-center' style={{width:'fit-content',marginBottom:"10px"}}>Refresh Balance</Button>     
        </div>




        <div className='card mx-auto text-center' id='item2' style={{backgroundColor: "#9b17b8",width:"70%",marginTop:"0px",marginBottom:"40px"}}>
        <h3 className='card-title' style={{textDecoration:"underline",color:'white'}}>First Time Participating?</h3>
      <h6 className='card-title mt-8'style={{color:"white"}}>Approve the dApp to access your $CANDY.</h6>
         <Button onClick={approve} className='btn btn-secondary mx-auto text-center' style={{width:'fit-content',marginBottom:"10px"}}>Approve</Button>
        </div>



</div>
    </div>
) ;
}

export default Lottery;



