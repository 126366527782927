import './App.css';
import { Button } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import React from 'react';
import 'sf-font';
import Web3Modal from "web3modal";
import WalletConnectProvider from "@walletconnect/web3-provider";
import Web3 from 'web3';
import truncateEthAddress from 'truncate-eth-address';
import { Navbar,Nav } from 'react-bootstrap'
import { Link } from 'react-router-dom';
import LOGO from './art.png'
import { useRef, useState } from 'react';

const ABI = [
  {
    inputs: [
      { internalType: "contract CANDY", name: "_token", type: "address" },
    ],
    stateMutability: "nonpayable",
    type: "constructor",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: "address",
        name: "previousOwner",
        type: "address",
      },
      {
        indexed: true,
        internalType: "address",
        name: "newOwner",
        type: "address",
      },
    ],
    name: "OwnershipTransferred",
    type: "event",
  },
  { stateMutability: "payable", type: "fallback" },
  {
    inputs: [{ internalType: "uint256", name: "_amount", type: "uint256" }],
    name: "buyTickets",
    outputs: [],
    stateMutability: "payable",
    type: "function",
  },
  {
    inputs: [],
    name: "getLatestPrice",
    outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "owner",
    outputs: [{ internalType: "address", name: "", type: "address" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [{ internalType: "bool", name: "_state", type: "bool" }],
    name: "pauseSaleState",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [],
    name: "pauseState",
    outputs: [{ internalType: "bool", name: "", type: "bool" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "renounceOwnership",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [],
    name: "ticketCostUsd",
    outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "tokenAddress",
    outputs: [{ internalType: "address", name: "", type: "address" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [{ internalType: "address", name: "newOwner", type: "address" }],
    name: "transferOwnership",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      { internalType: "uint256", name: "_newTicketCostUsd", type: "uint256" },
    ],
    name: "updateticketCostUsd",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [{ internalType: "address", name: "_to", type: "address" }],
    name: "withdraw",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  { stateMutability: "payable", type: "receive" },
];

const NFTABI = [
  {
    "inputs":[{"internalType":"string","name":"_name","type":"string"},
    {"internalType":"string","name":"_symbol","type":"string"},
    {"internalType":"string","name":"_initBaseURI","type":"string"},
    {"internalType":"string","name":"_initNotRevealedUri","type":"string"}],
    "stateMutability":"nonpayable","type":"constructor"},
    {"anonymous":false,
    "inputs":[{"indexed":true,"internalType":"address","name":"owner","type":"address"},
    {"indexed":true,"internalType":"address","name":"approved","type":"address"},
    {"indexed":true,"internalType":"uint256","name":"tokenId","type":"uint256"}],
    "name":"Approval","type":"event"},
    {"anonymous":false,
    "inputs":[{"indexed":true,"internalType":"address","name":"owner","type":"address"},
    {"indexed":true,"internalType":"address","name":"operator","type":"address"},
    {"indexed":false,"internalType":"bool","name":"approved","type":"bool"}],
    "name":"ApprovalForAll","type":"event"},
    {"anonymous":false,
    "inputs":[{"indexed":true,"internalType":"address","name":"previousOwner","type":"address"},
    {"indexed":true,"internalType":"address","name":"newOwner","type":"address"}],
    "name":"OwnershipTransferred","type":"event"},
    {"anonymous":false,
    "inputs":[{"indexed":true,"internalType":"address","name":"from","type":"address"},
    {"indexed":true,"internalType":"address","name":"to","type":"address"},
    {"indexed":true,"internalType":"uint256","name":"tokenId","type":"uint256"}],
    "name":"Transfer","type":"event"},
    {"inputs":[{"internalType":"address","name":"to","type":"address"},
    {"internalType":"uint256","name":"tokenId","type":"uint256"}],
    "name":"approve","outputs":[],"stateMutability":"nonpayable","type":"function"},
    {"inputs":[{"internalType":"address","name":"owner","type":"address"}],
    "name":"balanceOf","outputs":[{"internalType":"uint256","name":"","type":"uint256"}],
    "stateMutability":"view","type":"function"},
    {"inputs":[],
    "name":"baseExtension",
    "outputs":[{"internalType":"string","name":"","type":"string"}],
    "stateMutability":"view","type":"function"},
    {"inputs":[],"name":"baseURI",
    "outputs":[{"internalType":"string","name":"","type":"string"}],
    "stateMutability":"view","type":"function"},
    {"inputs":[],
    "name":"cost",
    "outputs":[{"internalType":"uint256","name":"","type":"uint256"}],
    "stateMutability":"view","type":"function"},
    {"inputs":[{"internalType":"uint256","name":"tokenId","type":"uint256"}],
    "name":"getApproved",
    "outputs":[{"internalType":"address","name":"","type":"address"}],
    "stateMutability":"view","type":"function"},
    {"inputs":[{"internalType":"address","name":"owner","type":"address"},
    {"internalType":"address","name":"operator","type":"address"}],
    "name":"isApprovedForAll",
    "outputs":[{"internalType":"bool","name":"","type":"bool"}],
    "stateMutability":"view","type":"function"},
    {"inputs":[],"name":"maxMintAmount",
    "outputs":[{"internalType":"uint256","name":"",
    "type":"uint256"}],
    "stateMutability":"view","type":"function"},
    {"inputs":[],
    "name":"maxSupply",
    "outputs":[{"internalType":"uint256","name":"","type":"uint256"}],
    "stateMutability":"view","type":"function"},
    {"inputs":[{"internalType":"uint256","name":"_mintAmount","type":"uint256"}],
    "name":"mint",
    "outputs":[],"stateMutability":"payable","type":"function"},
    {"inputs":[],"name":"name",
    "outputs":[{"internalType":"string","name":"","type":"string"}],
    "stateMutability":"view","type":"function"},
    {"inputs":[],"name":"notRevealedUri",
    "outputs":[{"internalType":"string","name":"","type":"string"}],
    "stateMutability":"view","type":"function"},
    {"inputs":[],"name":"owner",
    "outputs":[{"internalType":"address","name":"","type":"address"}],
    "stateMutability":"view","type":"function"},
    {"inputs":[{"internalType":"uint256","name":"tokenId","type":"uint256"}],
    "name":"ownerOf",
    "outputs":[{"internalType":"address","name":"","type":"address"}],
    "stateMutability":"view","type":"function"},
    {"inputs":[{"internalType":"bool","name":"_state","type":"bool"}],
    "name":"pause",
    "outputs":[],"stateMutability":"nonpayable","type":"function"},
    {"inputs":[],"name":"paused",
    "outputs":[{"internalType":"bool","name":"","type":"bool"}],
    "stateMutability":"view","type":"function"},
    {"inputs":[{"internalType":"address","name":"_user","type":"address"}],
    "name":"removeWhitelistUser",
    "outputs":[],"stateMutability":"nonpayable","type":"function"},
    {"inputs":[],"name":"renounceOwnership",
    "outputs":[],"stateMutability":"nonpayable","type":"function"},
    {"inputs":[],"name":"reveal","outputs":[],
    "stateMutability":"nonpayable","type":"function"},
    {"inputs":[],"name":"revealed",
    "outputs":[{"internalType":"bool","name":"","type":"bool"}],
    "stateMutability":"view","type":"function"},
    {"inputs":[{"internalType":"address","name":"from","type":"address"},
    {"internalType":"address","name":"to","type":"address"},
    {"internalType":"uint256","name":"tokenId","type":"uint256"}],
    "name":"safeTransferFrom",
    "outputs":[],"stateMutability":"nonpayable","type":"function"},
    {"inputs":[{"internalType":"address","name":"from","type":"address"},
    {"internalType":"address","name":"to","type":"address"},
    {"internalType":"uint256","name":"tokenId","type":"uint256"},
    {"internalType":"bytes","name":"_data","type":"bytes"}],
    "name":"safeTransferFrom","outputs":[],
    "stateMutability":"nonpayable","type":"function"},
    {"inputs":[{"internalType":"address","name":"operator","type":"address"},
    {"internalType":"bool","name":"approved","type":"bool"}],
    "name":"setApprovalForAll","outputs":[],
    "stateMutability":"nonpayable","type":"function"},
    {"inputs":[{"internalType":"string",
    "name":"_newBaseExtension","type":"string"}],
    "name":"setBaseExtension","outputs":[],
    "stateMutability":"nonpayable","type":"function"},
    {"inputs":[{"internalType":"string",
    "name":"_newBaseURI","type":"string"}],
    "name":"setBaseURI","outputs":[],
    "stateMutability":"nonpayable","type":"function"},
    {"inputs":[{"internalType":"uint256",
    "name":"_newCost","type":"uint256"}],
    "name":"setCost","outputs":[],
    "stateMutability":"nonpayable","type":"function"},
    {"inputs":[{"internalType":"string",
    "name":"_notRevealedURI","type":"string"}],
    "name":"setNotRevealedURI","outputs":[],
    "stateMutability":"nonpayable","type":"function"},
    {"inputs":[{"internalType":"uint256",
    "name":"_newmaxMintAmount","type":"uint256"}],
    "name":"setmaxMintAmount","outputs":[],
    "stateMutability":"nonpayable","type":"function"},
    {"inputs":[{"internalType":"bytes4",
    "name":"interfaceId","type":"bytes4"}],
    "name":"supportsInterface",
    "outputs":[{"internalType":"bool",
    "name":"","type":"bool"}],
    "stateMutability":"view","type":"function"},
    {"inputs":[],"name":"symbol",
    "outputs":[{"internalType":"string","name":"","type":"string"}],
    "stateMutability":"view",
    "type":"function"},
    {"inputs":[{"internalType":"uint256",
    "name":"index","type":"uint256"}],
    "name":"tokenByIndex",
    "outputs":[{"internalType":"uint256",
    "name":"","type":"uint256"}],
    "stateMutability":"view",
    "type":"function"},
    {"inputs":[{"internalType":"address",
    "name":"owner","type":"address"},
    {"internalType":"uint256",
    "name":"index","type":"uint256"}],
    "name":"tokenOfOwnerByIndex",
    "outputs":[{"internalType":"uint256",
    "name":"","type":"uint256"}],
    "stateMutability":"view",
    "type":"function"},
    {"inputs":[{"internalType":"uint256",
    "name":"tokenId","type":"uint256"}],
    "name":"tokenURI",
    "outputs":[{"internalType":"string","name":"","type":"string"}],
    "stateMutability":"view",
    "type":"function"},
    {"inputs":[],
    "name":"totalSupply",
    "outputs":[{"internalType":"uint256","name":"","type":"uint256"}],
    "stateMutability":"view",
    "type":"function"},
    {"inputs":[{"internalType":"address","name":"from","type":"address"},
    {"internalType":"address","name":"to","type":"address"},
    {"internalType":"uint256","name":"tokenId","type":"uint256"}],
    "name":"transferFrom",
    "outputs":[],"stateMutability":"nonpayable","type":"function"},
    {"inputs":[{"internalType":"address","name":"newOwner","type":"address"}],
    "name":"transferOwnership",
    "outputs":[],"stateMutability":"nonpayable","type":"function"},
    {"inputs":[{"internalType":"address","name":"_owner","type":"address"}],
    "name":"walletOfOwner",
    "outputs":[{"internalType":"uint256[]","name":"","type":"uint256[]"}],
    "stateMutability":"view","type":"function"},
    {"inputs":[{"internalType":"address","name":"_user","type":"address"}],
    "name":"whitelistUser","outputs":[],"stateMutability":"nonpayable","type":"function"},
    {"inputs":[{"internalType":"address","name":"","type":"address"}],
    "name":"whitelisted",
    "outputs":[{"internalType":"bool","name":"","type":"bool"}],
    "stateMutability":"view","type":"function"},
    {"inputs":[],"name":"withdraw","outputs":[],
    "stateMutability":"payable",
    "type":"function"}];

const TOKENABI = [
  {
    inputs: [
      { internalType: "string", name: "_name", type: "string" },
      { internalType: "string", name: "_symbol", type: "string" },
      { internalType: "string", name: "_initBaseURI", type: "string" },
    ],
    stateMutability: "nonpayable",
    type: "constructor",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: "address",
        name: "owner",
        type: "address",
      },
      {
        indexed: true,
        internalType: "address",
        name: "approved",
        type: "address",
      },
      {
        indexed: true,
        internalType: "uint256",
        name: "tokenId",
        type: "uint256",
      },
    ],
    name: "Approval",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: "address",
        name: "owner",
        type: "address",
      },
      {
        indexed: true,
        internalType: "address",
        name: "operator",
        type: "address",
      },
      { indexed: false, internalType: "bool", name: "approved", type: "bool" },
    ],
    name: "ApprovalForAll",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: "address",
        name: "previousOwner",
        type: "address",
      },
      {
        indexed: true,
        internalType: "address",
        name: "newOwner",
        type: "address",
      },
    ],
    name: "OwnershipTransferred",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      { indexed: true, internalType: "address", name: "from", type: "address" },
      { indexed: true, internalType: "address", name: "to", type: "address" },
      {
        indexed: true,
        internalType: "uint256",
        name: "tokenId",
        type: "uint256",
      },
    ],
    name: "Transfer",
    type: "event",
  },
  {
    inputs: [{ internalType: "address[]", name: "_users", type: "address[]" }],
    name: "addUsersToPresale",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      { internalType: "address", name: "to", type: "address" },
      { internalType: "uint256", name: "tokenId", type: "uint256" },
    ],
    name: "approve",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [{ internalType: "address", name: "owner", type: "address" }],
    name: "balanceOf",
    outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "baseExtension",
    outputs: [{ internalType: "string", name: "", type: "string" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "baseURI",
    outputs: [{ internalType: "string", name: "", type: "string" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "cost",
    outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [{ internalType: "uint256", name: "tokenId", type: "uint256" }],
    name: "getApproved",
    outputs: [{ internalType: "address", name: "", type: "address" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      { internalType: "address", name: "owner", type: "address" },
      { internalType: "address", name: "operator", type: "address" },
    ],
    name: "isApprovedForAll",
    outputs: [{ internalType: "bool", name: "", type: "bool" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "maxMintAmount",
    outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "maxSupply",
    outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      { internalType: "address", name: "_to", type: "address" },
      { internalType: "uint256", name: "_mintAmount", type: "uint256" },
    ],
    name: "mint",
    outputs: [],
    stateMutability: "payable",
    type: "function",
  },
  {
    inputs: [],
    name: "name",
    outputs: [{ internalType: "string", name: "", type: "string" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "owner",
    outputs: [{ internalType: "address", name: "", type: "address" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [{ internalType: "uint256", name: "tokenId", type: "uint256" }],
    name: "ownerOf",
    outputs: [{ internalType: "address", name: "", type: "address" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [{ internalType: "bool", name: "_state", type: "bool" }],
    name: "pause",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [],
    name: "paused",
    outputs: [{ internalType: "bool", name: "", type: "bool" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "presaleCost",
    outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [{ internalType: "address[]", name: "_users", type: "address[]" }],
    name: "removeUsersFromPresale",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [],
    name: "renounceOwnership",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      { internalType: "address", name: "from", type: "address" },
      { internalType: "address", name: "to", type: "address" },
      { internalType: "uint256", name: "tokenId", type: "uint256" },
    ],
    name: "safeTransferFrom",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      { internalType: "address", name: "from", type: "address" },
      { internalType: "address", name: "to", type: "address" },
      { internalType: "uint256", name: "tokenId", type: "uint256" },
      { internalType: "bytes", name: "_data", type: "bytes" },
    ],
    name: "safeTransferFrom",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      { internalType: "address", name: "operator", type: "address" },
      { internalType: "bool", name: "approved", type: "bool" },
    ],
    name: "setApprovalForAll",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      { internalType: "string", name: "_newBaseExtension", type: "string" },
    ],
    name: "setBaseExtension",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [{ internalType: "string", name: "_newBaseURI", type: "string" }],
    name: "setBaseURI",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [{ internalType: "uint256", name: "_newCost", type: "uint256" }],
    name: "setCost",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [{ internalType: "uint256", name: "_newCost", type: "uint256" }],
    name: "setPresaleCost",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      { internalType: "uint256", name: "_newmaxMintAmount", type: "uint256" },
    ],
    name: "setmaxMintAmount",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [{ internalType: "bytes4", name: "interfaceId", type: "bytes4" }],
    name: "supportsInterface",
    outputs: [{ internalType: "bool", name: "", type: "bool" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "symbol",
    outputs: [{ internalType: "string", name: "", type: "string" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [{ internalType: "uint256", name: "index", type: "uint256" }],
    name: "tokenByIndex",
    outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      { internalType: "address", name: "owner", type: "address" },
      { internalType: "uint256", name: "index", type: "uint256" },
    ],
    name: "tokenOfOwnerByIndex",
    outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [{ internalType: "uint256", name: "tokenId", type: "uint256" }],
    name: "tokenURI",
    outputs: [{ internalType: "string", name: "", type: "string" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "totalSupply",
    outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      { internalType: "address", name: "from", type: "address" },
      { internalType: "address", name: "to", type: "address" },
      { internalType: "uint256", name: "tokenId", type: "uint256" },
    ],
    name: "transferFrom",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [{ internalType: "address", name: "newOwner", type: "address" }],
    name: "transferOwnership",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [{ internalType: "address", name: "_owner", type: "address" }],
    name: "walletOfOwner",
    outputs: [{ internalType: "uint256[]", name: "", type: "uint256[]" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [{ internalType: "address", name: "", type: "address" }],
    name: "whitelistedForPresale",
    outputs: [{ internalType: "bool", name: "", type: "bool" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "withdraw",
    outputs: [],
    stateMutability: "payable",
    type: "function",
  },
  { stateMutability: "payable", type: "receive" },
];

var web3 = null;
var account = null;
var contract = null;
var nftcontract = null;
var tokencontract = null;

const ADDRESS = "0x155bf8cE35779DFbC83E246Cddf34f327734C463";

const NFTADDRESS = "0x06A546A6FeE87aBc45f32dFb630b9A4c69eA243a";

const TOKENADDRESS = "0x824293562F37Dc988f9816A5aD3fefE139A9B2cc";




const providerOptions = {
  walletconnect: {
    package: WalletConnectProvider,
    options: {
      rpc: {
        56: 'https://bsc-dataseed1.binance.org'
      },
      chainId: 56
    }
  }
}

const web3Modal = new Web3Modal({
  network: "mainnet", // optional
  cacheProvider: true, // optional
  providerOptions, // required
  theme: "dark",
});





async function show() {
  var rawTicketCost = await contract.methods.getLatestPrice().call()
  console.log(rawTicketCost)
  var int = String(rawTicketCost).split(",")[0]
  var cost = Web3.utils.fromWei(int);
  var ticketCost = Number(cost).toFixed(5);
  document.getElementById("ticket-cost").innerHTML = ticketCost;
  var getbalance = await tokencontract.methods.balanceOf(account).call()
  var bal = String(getbalance).split(",")[0];
  var balrwd = Web3.utils.fromWei(bal);
  var totalbal = Number(balrwd).toFixed(2);
  document.getElementById("total-token").textContent = totalbal;
}

  function refreshPage() {
    window.location.reload(false);
  }



function TokenSale () {
  const ticketAmountRef = useRef()
  const [nft, setNft] =  useState([])

  async function connectWallet() {
    var provider = await web3Modal.connect();
    web3 = new Web3(provider);
    var accounts = await web3.eth.getAccounts();
    account = accounts[0];
    var finalAccount = `${truncateEthAddress(account)}`
    document.getElementById('wallet-address').textContent = finalAccount;
    contract = new web3.eth.Contract(ABI, ADDRESS);
    nftcontract = new web3.eth.Contract(NFTABI, NFTADDRESS);
    tokencontract = new web3.eth.Contract(TOKENABI, TOKENADDRESS)
    var nftDetected = await nftcontract.methods.walletOfOwner(account).call()
    console.log(nftDetected)
    setNft(nftDetected)

    setTimeout(show(),2000);
  }

  async function buy() {
    const ticketAmount = Number(ticketAmountRef.current.value)
    let ticketCost = Number(await contract.methods.getLatestPrice().call())
    let purchaseCost = ticketCost * ticketAmount
    console.log(purchaseCost)
    await contract.methods.buyTickets(ticketAmount).send({ from: account,value: String(purchaseCost)})
  }

  console.log(nft.length)

  return(
    <div className='App'>
        <div>
                  <div>
                    <Navbar bg="dark" variant="dark" expand="lg" sticky="top">
                        <Navbar.Toggle aria-controls="basic-navbar-nav" />
                        <Navbar.Collapse id="basic-navbar-nav" style={{justifyContent:"space-between"}}>
                        <Nav className="mr-auto">
                           <Nav.Link><Link style={{textDecoration:"none",color:"#C0C0C0"}} onClick={refreshPage}><b>Refresh Page</b></Link></Nav.Link> 
                           <Nav.Link> <Link style={{textDecoration:"none",color:"#C0C0C0"}} to="/"><b>Stake Your NFT's</b></Link></Nav.Link> 
                           <Nav.Link> <Link style={{textDecoration:"none",color:"#C0C0C0"}} to="/lottery"><b>Enter Round</b></Link></Nav.Link> 
                            </Nav>
                            <label type="button" onClick={connectWallet} className="btn btn-secondary" for="floatingInput" id="wallet-address" style={{color: "yellow",fontWeight: "700",marginTop: "16px",textShadow: "1px 1px black",}}>Connect Wallet</label>
                        </Navbar.Collapse>
                    </Navbar>
                    </div>
                    <div>
                </div>
                <div className='col' style={{color:"#5c4673"}}>
            <a href='https://horror-ape.club' ><img src={LOGO} alt="logo" width='35%' /></a>
            <h1 className='card-title' style={{textDecoration:"underline",color:'white'}}>Crazy Joker Premium Content</h1>

          </div>
        </div>
        
        {nft.length >= 1 ? 
        ( 
      <div className='card mx-auto text-center' id='wrapper' style={{width:"80vw",marginTop:"40px",backgroundColor:"green"}}>
        <div className='card mx-auto text-center' id='item' style={{backgroundColor: "#0c0b0d",width:"70%",marginTop:"40px"}}>
          <h2 className='card-title' style={{textDecoration:"underline"}}> Locked Content Here</h2>
          <h4 className='card-title' style={{textDecoration:"underline"}}> Your Balance</h4>
          <Button onClick={show} className='btn btn-warning mx-auto text-center' style={{width:'fit-content',marginBottom:"2px"}}>Show</Button>
          <div className='card-title mt-3'style={{color:"yellowgreen"}}>You Own: <span style={{color:"yellow"}} id='total-token'></span>&nbsp;CANDY</div>
        </div>
		<h6 style={{ color: "white",marginTop:"30px", fontSize:"20px"}}> Price: <span style={{color:"yellowgreen"}} id='ticket-cost'></span> <span style={{color:"yellow"}}>BNB</span> per CANDY</h6>
        <div className='card mx-auto text-center' id='item2' style={{backgroundColor: "#0c0b0d",width:"70%",marginTop:"40px",marginBottom:"40px"}}>
        <h4 className='card-title' style={{textDecoration:"underline",color:'white'}}>Purchase</h4>
          <div className='card-title' style={{color:"white",fontWeight:"600"}}><input ref={ticketAmountRef} id="inpt" type="number" name="amount" defaultValue="1" style={{ textAlign: "center", width: "25%",borderRadius:"10px",backgroundColor:"gray" }} />
                <br></br>
                <label for="inpt" style={{ color: "white", fontWeight: "500" }}>
                Purchase Amount
                </label></div>
          <Button id='enter-btn' onClick={buy} className='btn btn-warning mx-auto text-center' style={{width:'fit-content',marginBottom:"20px"}}>BUY</Button>
        </div>
    </div>) : 
    (<div className='text-center row' style={{}}><div className='col-xxl'><h1 style={{color:"yellowgreen", marginTop:"100px",textShadow:"1px 1px yellow"}}>You Hold No CJSF NFTs</h1><br /><a href='https:/crazyjokersweepfestival.com/'><Button style={{fontSize:"20px"}}> Mint CJSF</Button></a></div></div>)
   }
    </div>
   )
}

export default TokenSale;


