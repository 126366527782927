import 'bootstrap/dist/css/bootstrap.min.css';
import React from 'react';
import 'sf-font';
import './App.css';
import ReactDom from 'react-dom';
import './index.css';
import App from './App';
import 'bootstrap/dist/css/bootstrap.min.css';
import reportWebVitals from './reportWebVitals';
import Lottery from './Lottery'
import TokenSale from './TokenSale';
import Lottery2 from './Lottery2';
import Poker from './Poker';
import AlienPetz from './AlienPetz';
import CandyBotzMint from './CandyBotzMint';
import CJSFMint from './CJSFMint';
import CandyBotz from './CandyBotz';
import { BrowserRouter, Route, Switch } from 'react-router-dom';


ReactDom.render(
  <BrowserRouter>
  <Switch>
    <Route path='/' exact>
    <CJSFMint />
    </Route>
    <Route path='/cjsfstaking'>
    <App />
    </Route>
    <Route path='/lottery'>
    <Lottery />
    </Route>
    <Route path='/ticket-sale'>
    <TokenSale />
    </Route>
    <Route path='/lottery2'>
    <Lottery2 />
    </Route>
    <Route path='/AlienPetz'>
    <AlienPetz />
    </Route>
    <Route path='/Poker'>
    <Poker />
    </Route>
    <Route path='/CandyBotzMint'>
    <CandyBotzMint />
    </Route>
    <Route path='/CJSFMint'>
    <CJSFMint />
    </Route>
    <Route path='/CandyBotz'>
    <CandyBotz />
    </Route>
  </Switch>
  </BrowserRouter>
, document.getElementById('root'))


// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
